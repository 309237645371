@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");

//***********************************************************************************************
//*********************************** Main Page**************************************************
//***********************************************************************************************

html {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
}
a:link {
  color: inherit;
}
body {
  margin: 0;
  padding: 0;
}
main {
  background-image: url("./background-image.jpg");
  /* background-position: center top; */
  /* background-attachment: fixed; */
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 43vw;
  display: grid;
  grid-template-columns: 11.5% 27% 0.7% 25.7% 1.1% 24.6%;
  grid-template-rows: 14.3% 75% 50%;
}
.window {
  text-decoration: none;
  text-align: center;
  font-size: 3vw;
  font-weight: 700;
  writing-mode: vertical-lr;
  color: white;
  background-repeat: no-repeat;
  grid-column-start: 2;
  grid-row-start: 2;
  opacity: 0.1;
  background-size: cover;
}
.window:hover {
  opacity: 0.6;
}
#research {
  background-image: url(https://media2.giphy.com/media/55ip2hZMTXWnlZcyFt/giphy.gif?cid=790b76110302d25cd0e7e456636c6d5a2cdf78f507a81d44&rid=giphy.gif&ct=g);
}

#cs {
  background-image: url(https://media0.giphy.com/media/13HgwGsXF0aiGY/giphy.gif?cid=790b7611c551b45397ebfabb5fd045cef299ef4a601aa27b&rid=giphy.gif&ct=g);
  background-position: center top;
  color: black;
  background-color: rgb(32, 53, 37);
  grid-column-start: 4;
  grid-row-start: 2;
}
#cs:hover {
  opacity: 0.8;
}
#other {
  background-image: url(https://media0.giphy.com/media/26gJy43Tfj5knzZyU/giphy.gif?cid=790b7611c240c3b46c1888875d748dfa0386dbeba1edcba2&rid=giphy.gif&ct=g);
  grid-column-start: 6;
  grid-row-start: 2;
}
.main {
  font-family: "Playfair Display", serif;
  font-size: 4vw;
  color: deepskyblue;
  top: 3%;
  left: 40%;
  position: absolute;
  margin: 0;
  animation: transformMain 20s infinite;
}

.inverted {
  font-family: "Playfair Display", serif;
  top: 3%;
  left: 40%;
  font-size: 4vw;
  color: deepskyblue;
  position: absolute;
  transform: rotateX(180deg);
  color: transparent;
  margin: 0;
  font-style: italic;
  text-shadow: 1px -1em 3px deepskyblue;
  animation: transformInverted 20s infinite;
}

@keyframes transformMain {
  50% {
    transform: rotateX(180deg);
    color: transparent;
    margin: 0;
    font-style: italic;
    text-shadow: 1px -1em 3px deepskyblue;
  }
}
@keyframes transformInverted {
  50% {
    transform: rotateX(0deg);
    color: deepskyblue;
    margin: 0;
    font-style: normal;
    text-shadow: 0 0 0 deepskyblue;
  }
}

#mainLink1,
#mainLink2,
#mainLink3 {
  color: deepskyblue;
  font-size: large;
  font-family: "Rubik", sans-serif;
}
.mainLink:hover {
  color: rgb(255, 255, 255);
}
footer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 767px) {
  .main,
  .inverted {
    top: 50vw;
  }
}
@media (max-width: 420px) {
  .window {
    opacity: 0.3;
  }
  .mainLink {
    color: deepskyblue;
    font-size: 4vw;
    padding: 10px;
  }
  footer {
    margin-top: 30vw;
    text-align: center;
    flex-direction: column;
  }
}
//***********************************************************************************************
//*********************************** Programming**************************************************
//***********************************************************************************************

#csnav {
  position: fixed;
  background-image: url("../img/mount.png");
  background-size: cover;
  height: 19vh;
  width: 100vw;
  z-index: 2;
  box-shadow: 0px 25px 58px 21px #14213d;
}
.mountain {
  perspective: 40000px;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 3vh;
  text-decoration: none;
  transform-origin: 50% 100%;
}
.mountain:hover {
  color: transparent;
}
#mfirst {
  transform: rotateX(20deg) translateZ(0);
  top: 0;
  // background-color: #f1d8fd;
  color: #14213d;
}

#msecond {
  perspective: 400px;
  transform-origin: 50% 100%;
  top: 3vh;
  // background-color: #aaaeee;
  color: #102e6a;
}
#mthird {
  top: 6vh;
  // background-color: #546bc4;
  color: #0c02a2;
}
#mforth {
  top: 9vh;
  // background-color: #0c02a2;
  color: #546bc4;
}
#mfifth {
  top: 12vh;
  // background-color: #102e6a;
  color: #aaaeee;
}
#msixth {
  top: 15vh;
  // background-color: #14213d;
  color: #e3b1fb;
}

#home,
#projects,
#about,
#resume,
#blog,
#contact {
  position: relative;
  padding-top: 18vh;
  height: 100vh;
  width: 100vw;
  color: #aaaeee;
}
#home {
  // background: hsla(5, 15%, 14%, 1);
  box-shadow: 32px 52px 61px 63px rgba(87, 158, 92, 0.9);
  z-index: 1;

  background-image: linear-gradient(
      135deg,
      hsla(250, 27%, 17%, 0.9) 0%,
      hsla(218, 43%, 24%, 0.9) 25%,
      hsla(124, 18%, 31%, 0.9) 50%,
      hsla(124, 29%, 48%, 0.9) 75%,
      hsla(76, 96%, 41%, 0.9) 99%
    ),
    url(http://cdn.zmescience.com/wp-content/uploads/2015/11/Moss-1020x610.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
#programming-wrapper {
  width: 600vw;
  display: flex;
}

.firstLine {
  width: 50vw;
  padding-left: 30vw;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 10vh;
  margin-bottom: 5vh;
  white-space: nowrap;
  -webkit-animation: typing2 2s steps(40, end),
    blink 0.1s step-end infinite alternate;
  animation: typing2 2s steps(20, end), blink 0.1s step-end 10s alternate;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.secondLine {
  width: 80vw;
  padding-left: 20vw;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.17em solid #aaaeee;
  margin: 0;
  opacity: 0;
  padding-top: 0;
  -webkit-animation: typing2 2s steps(40, end),
    blink 0.1s step-end infinite alternate;
  animation: typing2 2s steps(20, end), blink 0.1s step-end infinite alternate;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes typing2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid #aaaeee;
  }
  100% {
    opacity: 1;
    border: none;
    border-right: none;
  }
}
.thirdLine {
  opacity: 0;
  overflow: hidden;
  width: 80vw;
  padding-left: 20vw;
  margin: 0;
  white-space: nowrap;
  border-right: 0.17em solid #aaaeee;
  -webkit-animation: typing2 2s steps(40, end),
    blink 0.1s step-end infinite alternate;
  animation: typing2 2s steps(20, end), blink 0.1s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes typing {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid #82b533;
  }
  100% {
    border: none;
  }
}

@keyframes blink {
  50% {
    border-color: #aaaeee;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: #aaaeee;
  }
}

//this a ladybug animation code:))
.bug {
  position: absolute;
  top: calc(18vh + 10vh + 10vw + 5vh + 5vw + 2vw);
  left: calc(58vw);
  transform: rotate(90deg);
  transform-origin: center;
  animation: animated_bug 20s 1 linear;
  -webkit-animation: animated_bug 5s 1 linear;
  -webkit-animation-delay: 4.6s;
  animation-delay: 4.6s;
  animation-fill-mode: forwards;
}
@keyframes animated_bug {
  100% {
    transform: translate(24vw, calc(-0vh - 10vw - 5vh - 3vw)) rotate(220deg);
  }
}

@-webkit-keyframes animated_bug {
  100% {
    transform: translate(24vw, calc(-10vh - 10vw - 5vh - 3vw)) scale(0.6)
      rotate(180deg);
  }
}

.ladybug {
  position: absolute;
  width: 50px;
  height: 60px;
  border-radius: 50%;
  background-color: black;
}
.wing {
  background-color: red;
  z-index: 1;
  margin: 1px 1px 0 0;
}
#wingleft {
  width: 25px;
  height: 40px;
  border-top-right-radius: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 20px;
  animation: animated_wingl 10s infinite linear;
  -webkit-animation: animated_wingl 10s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: 22px;
  left: -2px;
}

#wingright {
  width: 25px;
  height: 40px;
  border-top-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 50px;
  animation: animated_wingr 10s infinite linear;
  -webkit-animation: animated_wingr 10s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: 22px;
  left: 27px;
}
@keyframes animated_wingr {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes animated_wingl {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes animated_wingl {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(10deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(10deg);
  }
  40% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
  }
  70% {
    -webkit-transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(0deg);
  }
  90% {
    -webkit-transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

.eye {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 10px;
  z-index: 1;
}

.eye2 {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 30px;
  z-index: 1;
}
.eyelid {
  width: 12px;
  height: 12px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 9px;
  animation: animated_lid 2s infinite;
  -webkit-animation: animated_lid 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 2;
}
@keyframes animated_lid {
  0% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-4px);
  }
  80% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-4px);
  }
}

@-webkit-keyframes animated_lid {
  0% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-4px);
  }
  80% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-4px);
  }
}

.eyelid2 {
  width: 12px;
  height: 12px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 29px;
  animation: animated_lid 2s infinite;
  -webkit-animation: animated_lid 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 2;
}

.eyespot {
  width: 5px;
  height: 5px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 12px;
  z-index: 2;
}

.eyespot2 {
  width: 5px;
  height: 5px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 32px;
  z-index: 2;
}

.spot {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 48px;
  left: 3px;
  z-index: 3;
}

.spot2 {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 48px;
  left: 35px;
  z-index: 3;
}

.spot3 {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 35px;
  left: 10px;
  z-index: 3;
}

.spot4 {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 35px;
  left: 31px;
  z-index: 3;
}

.spot5 {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 3px;
  z-index: 3;
}

.spot6 {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 36px;
  z-index: 3;
}

.leg {
  width: 3px;
  height: 80px;
  background-color: black;
  border-radius: 50%;
  z-index: -3;
  position: absolute;
  top: -1px;
  left: 25px;
  transform: rotate(55deg);
  -webkit-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  animation: animated_leg 2s infinite;
  -webkit-animation: animated_leg 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.leg2 {
  width: 3px;
  height: 70px;
  background-color: black;
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  top: 10px;
  left: 23.5px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  animation: animated_leg2 2s infinite;
  -webkit-animation: animated_leg2 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.leg3 {
  width: 3px;
  height: 80px;
  background-color: black;
  border-radius: 50%;
  z-index: -2;
  position: absolute;
  top: -1px;
  left: 22px;
  -webkit-transform: rotate(125deg);
  transform: rotate(125deg);
  -moz-transform: rotate(125deg);
  animation: animated_leg3 2s infinite;
  -webkit-animation: animated_leg3 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes animated_leg {
  0% {
    transform: rotate(50deg);
  }
  10% {
    transform: rotate(60deg);
  }
  20% {
    transform: rotate(50deg);
  }
  30% {
    transform: rotate(60deg);
  }
  40% {
    transform: rotate(50deg);
  }
  50% {
    transform: rotate(60deg);
  }
  60% {
    transform: rotate(50deg);
  }
  70% {
    transform: rotate(60deg);
  }
  80% {
    transform: rotate(50deg);
  }
  90% {
    transform: rotate(60deg);
  }
  100% {
    transform: rotate(50deg);
  }
}

@-webkit-keyframes animated_leg {
  0% {
    -webkit-transform: rotate(50deg);
  }
  10% {
    -webkit-transform: rotate(60deg);
  }
  20% {
    -webkit-transform: rotate(50deg);
  }
  30% {
    -webkit-transform: rotate(60deg);
  }
  40% {
    -webkit-transform: rotate(50deg);
  }
  50% {
    -webkit-transform: rotate(60deg);
  }
  60% {
    -webkit-transform: rotate(50deg);
  }
  70% {
    -webkit-transform: rotate(60deg);
  }
  80% {
    -webkit-transform: rotate(50deg);
  }
  90% {
    -webkit-transform: rotate(60deg);
  }
  100% {
    -webkit-transform: rotate(50deg);
  }
}

@keyframes animated_leg2 {
  0% {
    transform: rotate(85deg);
  }
  10% {
    transform: rotate(95deg);
  }
  20% {
    transform: rotate(85deg);
  }
  30% {
    transform: rotate(95deg);
  }
  40% {
    transform: rotate(85deg);
  }
  50% {
    transform: rotate(95deg);
  }
  60% {
    transform: rotate(85deg);
  }
  70% {
    transform: rotate(95deg);
  }
  80% {
    transform: rotate(85deg);
  }
  90% {
    transform: rotate(95deg);
  }
  100% {
    transform: rotate(85deg);
  }
}

@-webkit-keyframes animated_leg2 {
  0% {
    -webkit-transform: rotate(85deg);
  }
  10% {
    -webkit-transform: rotate(95deg);
  }
  20% {
    -webkit-transform: rotate(85deg);
  }
  30% {
    -webkit-transform: rotate(95deg);
  }
  40% {
    -webkit-transform: rotate(85deg);
  }
  50% {
    -webkit-transform: rotate(95deg);
  }
  60% {
    -webkit-transform: rotate(85deg);
  }
  70% {
    -webkit-transform: rotate(95deg);
  }
  80% {
    -webkit-transform: rotate(85deg);
  }
  90% {
    -webkit-transform: rotate(95deg);
  }
  100% {
    -webkit-transform: rotate(85deg);
  }
}

@keyframes animated_leg3 {
  0% {
    transform: rotate(120deg);
  }
  10% {
    transform: rotate(130deg);
  }
  20% {
    transform: rotate(120deg);
  }
  30% {
    transform: rotate(130deg);
  }
  40% {
    transform: rotate(120deg);
  }
  50% {
    transform: rotate(130deg);
  }
  60% {
    transform: rotate(120deg);
  }
  70% {
    transform: rotate(130deg);
  }
  80% {
    transform: rotate(120deg);
  }
  90% {
    transform: rotate(130deg);
  }
  100% {
    transform: rotate(120deg);
  }
}

@-webkit-keyframes animated_leg3 {
  0% {
    -webkit-transform: rotate(120deg);
  }
  10% {
    -webkit-transform: rotate(130deg);
  }
  20% {
    -webkit-transform: rotate(120deg);
  }
  30% {
    -webkit-transform: rotate(130deg);
  }
  40% {
    -webkit-transform: rotate(120deg);
  }
  50% {
    -webkit-transform: rotate(130deg);
  }
  60% {
    -webkit-transform: rotate(120deg);
  }
  70% {
    -webkit-transform: rotate(130deg);
  }
  80% {
    -webkit-transform: rotate(120deg);
  }
  90% {
    -webkit-transform: rotate(130deg);
  }
  100% {
    -webkit-transform: rotate(120deg);
  }
}

.sun {
  position: absolute;
  top: -5vw;
  transform: scale(0.4);
  right: calc(-320px + 13vw);
  width: 40rem;
  height: 40rem;
  margin: 3rem auto;
  background: radial-gradient(transparent 50%, white),
    radial-gradient(yellow, transparent 70%);
  -webkit-mask-image: radial-gradient(rgba(0, 0, 0, 1) 40%, transparent 65%);
  mask-image: radial-gradient(rgba(0, 0, 0, 1) 40%, transparent 65%);
  border-radius: 50%;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    border-radius: 50%;
    -webkit-mask-image: radial-gradient(rgba(0, 0, 0, 1) 40%, transparent 65%);
    mask-image: radial-gradient(rgba(0, 0, 0, 1) 40%, transparent 65%);
  }

  &::before {
    background: repeating-conic-gradient(
      from 0deg,
      yellow 0deg 20deg,
      transparent 20deg 40deg
    );
    animation: rotate 720s linear, scale 3s linear infinite;
  }

  &::after {
    background: radial-gradient(
        yellow,
        orange 27%,
        transparent calc(27% + 3px) 100%
      ),
      radial-gradient(gold, transparent 70%),
      repeating-conic-gradient(
        from 0deg,
        gold 0deg 5deg,
        transparent 5deg 10deg
      );
    transform: rotate(15deg);
    animation: rotate 360s linear;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

#moth {
  text-decoration: underline;
  appearance: none;
  cursor: default;
  color: #546bc4;
  background-color: inherit;
  box-sizing: border-box;
  margin: 0em;
  font: inherit;
  padding: 0;
  border-width: 0;
  border-style: inherit;
  border-color: transparent;
  border-image: inherit;
}
#moth:hover {
  color: #e3b1fb;
  text-shadow: 0 0 0 transparent, 0 0 10px #2695ff,
    0 0 20px rgba(38, 149, 255, 0.5), 0 0 40px #2695ff, 0 0 100px #2695ff,
    0 0 200px #2695ff, 0 0 300px #2695ff, 0 0 500px #2695ff;
  outline: none;
}
.neon {
  color: #e3b1fb !important;
  text-shadow: 0 0 0 transparent, 0 0 10px #2695ff,
    0 0 20px rgba(38, 149, 255, 0.5), 0 0 40px #2695ff, 0 0 100px #2695ff,
    0 0 200px #2695ff, 0 0 300px #2695ff, 0 0 500px #2695ff;
}
#moth:focus {
  outline: none;
}
.moth {
  z-index: 2;
  transform: scale(0.5);
  position: absolute;
  left: 35vw;
  bottom: 0vh;
  border-radius: 50%;
  background: #020202;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  animation: 1 flybabyfly 3s linear;
  animation-fill-mode: forwards;
}
@keyframes flybabyfly {
  100% {
    transform: translateY(calc(-60vh + 3vw));
  }
}

.moth:before {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  height: 40px;
  width: 20px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid black;
  top: -21px;
  left: -26px;
  transform: rotate(-69deg);
}

.moth:after {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  height: 40px;
  width: 20px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid black;
  top: -21px;
  left: 25px;
  transform: rotate(73deg);
}

.wings {
  position: absolute;
  left: 10px;
  top: 10px;
}

.body {
  height: 80px;
  width: 20px;
  border-radius: 20px 20px 30px 31px / 20px 20px 80px 80px;
  position: absolute;
  top: 10px;
  left: -2px;
  background: #777777;
  border: 2px solid black;
}

.wings:before {
  content: "";
  position: absolute;
  display: block;
  left: -48px;
  top: 13px;
  width: 70px;
  height: 70px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    10deg,
    rgb(149, 154, 155) 0%,
    rgb(45, 45, 61) 55%,
    rgba(2, 0, 36, 1) 100%
  );
  border-radius: 100% 10px 100% 10px;
  animation: animationWings 700ms cubic-bezier(0.61, 1, 0.88, 1) infinite;
  transform: rotate(-40deg);
}

.wings:after {
  content: "";
  position: absolute;
  display: block;
  left: -24px;
  top: 13px;
  width: 70px;
  height: 70px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    10deg,
    rgb(149, 154, 155) 0%,
    rgb(45, 45, 61) 55%,
    rgba(2, 0, 36, 1) 100%
  );
  border-radius: 10px 100% 10px 100%;
  animation: animationWingsRight 700ms cubic-bezier(0.61, 1, 0.88, 1) infinite;
  transform: rotate(40deg);
}

.wings2 {
  position: absolute;
  left: 15px;
  top: 35px;
}

.wings2:after {
  content: "";
  position: absolute;
  display: block;
  left: -20px;
  top: 13px;
  width: 40px;
  height: 50px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    10deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(9, 9, 121, 1) 55%,
    rgba(2, 0, 36, 1) 100%
  );
  border-radius: 10px 100% 10px 100%;
  animation: animationWingsRight 700ms cubic-bezier(0.61, 1, 0.88, 1) infinite;
  transform: rotate(40deg);
}

.wings2:before {
  content: "";
  position: absolute;
  display: block;
  left: -29px;
  top: 13px;
  width: 40px;
  height: 50px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    10deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(9, 9, 121, 1) 55%,
    rgba(2, 0, 36, 1) 100%
  );
  border-radius: 100% 10px 100% 10px;
  animation: animationWings 700ms cubic-bezier(0.61, 1, 0.88, 1) infinite;
  transform: rotate(-40deg);
}

@keyframes animationWings {
  50% {
    transform: translate(-20px, -13px) rotate(5deg);
  }
}
@keyframes animationWingsRight {
  50% {
    transform: translate(24px, -13px) rotate(-5deg);
  }
}
.ladder {
  left: 35vw;
  position: absolute;
  height: 100px;
  width: 5vw;
  border-left: black;
  border-right: black;
  border-right-style: solid;
  border-left-style: solid;
  border-right-width: 5px;
  border-left-width: 5px;
  border-left-style: solid;
  background-color: transparent;
  background: repeating-linear-gradient(
    transparent,
    transparent 20px,
    #000 20px,
    #000 40px
  );
}
.hidden {
  opacity: 0;
}
#about {
  background-image: url("../img/computer-3128030_960_720.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
#columns {
  column-count: 3;
}
.aboutMe {
  font-size: 14px;
  text-align: justify;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  margin: auto;
  margin-top: 19vh;
  height: 250px;
  width: 700px;
  border-radius: 2%;
  transform: rotate(-25deg);
  z-index: 0;
  padding: 20px;
  color: rgb(253, 245, 169);
  cursor: pointer;
}
.aboutMe:hover {
  transform: rotate(10deg);
}

#skills {
  columns: unset;
}
#projects {
  // background-image: url("../img/71T+sKOvvML.jpg");
  background-image: url("http://g00.eu/wp-content/uploads/2017/03/parts_20170323-1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#gitfacts {
  margin: auto;
}
.scrollleft {
  width: 50px;
  position: absolute;
  top: 50vh;
  transform: rotate(180deg);
  left: 5vw;
}
.scrollleft:hover {
  transform: rotate(180deg) scale(2);
}
.scrollright {
  opacity: 0.5;
  width: 50px;
  position: absolute;
  top: 50vh;
  right: 5vw;
}
.scrollright:hover {
  transform: scale(2);
}
#resume {
  background-image: linear-gradient(
      135deg,
      hsla(250, 27%, 17%, 0.9) 0%,
      hsla(218, 43%, 24%, 0.9) 25%,
      hsla(124, 18%, 31%, 0.9) 50%,
      hsla(124, 29%, 48%, 0.9) 75%,
      hsla(76, 96%, 41%, 0.9) 99%
    ),
    url(http://cdn.zmescience.com/wp-content/uploads/2015/11/Moss-1020x610.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  perspective: 300px;
}

#projectsContainer {
  margin: 30px auto;
  display: grid;
  height: 75vh;
  width: 80vw;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
}
.projects {
  height: calc((75vh - 60px - 30px) / 2);
  width: calc((80vw - 10vw - 60px) / 3);
  background-color: rgba(255, 255, 255, 0.5);
  border: rgba(0, 0, 0, 0.582) solid 15px;
  border-radius: 4px;
  position: relative;
}
.projects:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
a:link {
  color: unset;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.pheader {
  font-size: 15px;
  font-family: "Playfair Display", serif;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(20px);
  text-align: center;
  margin: 0;
}
.thumbnail {
  display: block;
  margin: auto;
}
.thumbimg {
  height: calc((75vh - 60px - 30px) / 3);
  // width: auto;
  margin: auto;
  display: block;
}
.dfooter {
  font-size: 15px;
  font-style: italic;
  color: white;
  height: 20%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(20px);
  position: absolute;
  display: block;
  bottom: 0;
}

#bb {
  display: block;
  text-align: center;
  margin: 15vh auto;
  font-size: 30px;
  font-weight: 1000;
}
#pdf {
  display: block;
  margin: auto;
  width: 640px;
  height: 550px;
}
#contact {
  background-image: linear-gradient(
      135deg,
      hsla(250, 27%, 17%, 0.9) 0%,
      hsla(218, 43%, 24%, 0.9) 25%,
      hsla(124, 18%, 31%, 0.9) 50%,
      hsla(124, 29%, 48%, 0.9) 75%,
      hsla(76, 96%, 41%, 0.9) 99%
    ),
    url(http://cdn.zmescience.com/wp-content/uploads/2015/11/Moss-1020x610.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  perspective: 300px;
}
#contactform {
  display: block;
  margin: auto;
  margin-top: 0vh;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  width: 50%;
  padding-left: 30px;
  border-radius: 5px;
  perspective: 10px;
  padding: 15px;
  font-size: 17px;
  font-family: "Playfair Display", serif;
  font-weight: 900;
  transform: rotateX(10deg);
}
.contactInput {
  width: 90%;
  color: inherit;
  background-color: rgba(255, 255, 255, 0.239);
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  color: black;
  font-size: 17px;
}
.contactInput:hover {
  background-color: rgba(255, 255, 255, 0.452);
}
#message {
  height: 200px;
}
#contactsubmit {
  background-color: rgba(53, 90, 64, 0.589);
  display: block;
  color: inherit;
  width: 80px;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-family: "Playfair Display", serif;
}
#contactsubmit:hover {
  background-color: rgba(56, 170, 90, 0.554);
  font-weight: 1000;
}
@media (max-width: 420px) {
  #programming-wrapper {
    width: 100vw;
    // height: 100;
    touch-action: pan-y;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    // overflow: scroll;
    box-shadow: 32px 52px 61px 63px rgba(87, 158, 92, 0.9);
    z-index: 1;

    background-image: linear-gradient(
        135deg,
        hsla(250, 27%, 17%, 0.9) 0%,
        hsla(218, 43%, 24%, 0.9) 25%,
        hsla(124, 18%, 31%, 0.9) 50%,
        hsla(124, 29%, 48%, 0.9) 75%,
        hsla(76, 96%, 41%, 0.9) 99%
      ),
      url(http://cdn.zmescience.com/wp-content/uploads/2015/11/Moss-1020x610.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  body,
  html {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  #root {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  #csnav {
    position: fixed;
    width: 100vw;
    top: 0;
    overflow-y: auto;
  }
  #projects,
  #about,
  #resume,
  #blog,
  #contact {
    position: initial;
    padding-top: 0;
    height: 100vh;
    width: auto;
    color: #aaaeee;
  }
  .sun {
    position: absolute;
    top: -45vw;
    transform: scale(0.2);
    position: inline-block;
  }
  .bug {
    position: absolute;
    top: calc(18vh + 10vh + 10vw + 5vh + 5vw + 2vw);
    left: calc(58vw);
  }
  .moth {
    transform: scale(0.5);
  }
  .secondLine {
    margin-top: 90px;
  }
  .secondLine,
  .thirdLine {
    transform: scale(1.3);
  }
  .scrollright,
  .scrollleft {
    opacity: 0;
  }
  #home {
    height: 60vh;
  }
  #columns {
    column-count: 1;
  }
  .aboutMe {
    background-color: rgba(23, 65, 30, 0.452);
    margin-top: 15vh;
    height: auto;
    width: 80vw;
    transform: initial;
  }
  .aboutMe:hover {
    transform: initial;
  }
  #blog {
    background-image: none;
    height: 40vh;
  }
  #projects {
    height: 100%;
  }
  .projects {
    height: 30vh;
    width: 80vw;
  }
  #projectsContainer {
    background-image: url("http://g00.eu/wp-content/uploads/2017/03/parts_20170323-1.jpg");
    background-size: contain;
    background-position: center;
    background-repeat: repeat;
    display: block;
    height: 100%;
    width: 80vw;
  }
  #resume {
    background-image: none;
    height: 60vh;
  }
  #pdf {
    display: block;
    margin: auto;
    width: 80vw;
    height: 100%;
  }
  #contact {
    background-image: none;
  }
  #contact {
    perspective: none;
  }
  #contactform {
    margin-top: 10vh;
    background-color: rgba(10, 39, 202, 0.472);
    backdrop-filter: blur(5px);
    width: 80%;
    padding-left: 30px;
    border-radius: 5px;
    perspective: 10px;
    padding: 15px;
    font-size: 17px;
    font-family: "Playfair Display", serif;
    font-weight: 900;
    transform: rotateX(10deg);
  }
}
